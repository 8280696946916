import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import ImageUploader from '../../../elementsHTML/ImageUploader';

const useStyles = makeStyles({
  textfield: {
    marginTop: '5%',
    marginBottom: '5%',
  },
  imageUploader: {
    flexDirection: 'row',
    justifyContent: 'flex-start !important',
    marginTop: '5%',
    marginBottom: '5%',
    '& > div': {
      marginRight: '5%'
    }
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  formcontrol: {
    display: 'flex',
    marginBottom: '5%',
    '& label': {
      width: '50%',
      fontSize: '0.9em'
    },
    '&.multiline': {
      display: 'flex',
      flexDirection: 'column',
      '& label': {
        width: '100%'
      }
    }
  },
  imageComponent: {
    height: '40px',
    width: 'auto',
    marginRight: '2%'
  }
});

const MainConfigComponent = (props: any) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  return (
    <div className={classes.formWrapper}>
      <TextField
        id="name"
        className={classes.textfield}
        label={t('Name')}
        variant="outlined"
        value={props.item.name}
        onChange={(e) => {
          props.item.name = e.target.value;
          props.setObjectData(props.item);
        }}
      />

      <div className={classes.formcontrol + ' multiline'}>
        <label id="progressionImage-label">Description</label>
        <TextField
          id="url"
          className={classes.textfield}
          label=''
          variant="outlined"
          value={props.item.description ? props.item.description : ''}
          onChange={(e) => {
            props.item.description = e.target.value;
            props.setObjectData(props.item);
          }}
        />
      </div>
      <div className={classes.formcontrol + ' multiline'}>
        <label id="progressionImage-label">Publication Date</label>
        <TextField
          id="publicationDate"
          type="date"
          className={classes.textfield}
          label=''
          variant="outlined"
          value={props.item.publicationDate ? props.item.publicationDate : ''}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            props.item.publicationDate = e.target.value;
            props.setObjectData(props.item);
          }}
        />
      </div>
      <div className={classes.formcontrol + ' multiline'}>
        <label id="progressionImage-label">Preview Image</label>
        <ImageUploader
          title=''
          className={classes.imageUploader}
          style={{ flexDirection: 'row' }}
          image={props.item.newsImage ? props.item.newsImage : ''}
          imageRatio={1}
          callback={(fileKey: any) => {
            if (fileKey) {
              props.item.newsImage = fileKey;
              props.setObjectData(props.item);
            }
          }}
        />
      </div>
      <div className={classes.formcontrol + ' multiline'}>
        <label id="progressionImage-label">URL to new resource</label>
        <TextField
          id="urlToResource"
          type="text"
          className={classes.textfield}
          label=''
          variant="outlined"
          value={props.item.urlToResource ? props.item.urlToResource : ''}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            props.item.urlToResource = e.target.value;
            props.setObjectData(props.item);
          }}
        />
      </div>
    </div>
  );
};

export default function LibraryMainConfig(props: any) {
  return (
    <div >
      <MainConfigComponent {...props} />
    </div>
  );
}
