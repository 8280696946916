import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import ValidationComponent from '../elementsHTML/ValidationComponent';
import MainConfigFormFactory from '../factories/MainConfigFormFactory';
import ObjectDefinitionFactory from '../factories/ObjectDefinitionFactory';

const useStyles = makeStyles({
  inputWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > div': {
      paddingTop: '1vw',
    },
  },
});

const ThemeEdition = (props: any) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const parentIdQuerySrting = props.objectType === 'theme' ? `theme_id=${props.mainConfig.id}` : '';
  const subObjectArraySelected = props.selectedObjectDefinition.object;
  const MainConfigForm = MainConfigFormFactory(props.selectedObjectDefinition.objectType);
  const objectProperties = ObjectDefinitionFactory(props.selectedObjectDefinition.objectType);

  return (
    <div>
      {props.selectedObjectDefinition.index > -1 && subObjectArraySelected && (
        <div>
          <div className={classes.inputWrapper}>
            <div>
              {props.mainConfig && props.mainConfig.name === 'Adverts' ? 'Adverts n°' : t(`${props.selectedObjectDefinition.objectType}.edit`)}
              {props.selectedObjectDefinition.index + 1}
              {objectProperties.baseUrl && (props.mainConfig && props.mainConfig.name !== 'Adverts') && (
                <a
                  href={`${objectProperties.baseUrl}/${subObjectArraySelected.id}${parentIdQuerySrting}`}
                >
                  <IconButton size="small">
                    <SearchIcon />
                  </IconButton>
                </a>
              )}
              {props.selectedObjectDefinition.object.hasOwnProperty('valid') && (
                <>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <ValidationComponent
                    object={props.selectedObjectDefinition.object}
                    editionMode={props.editionMode}
                    markAsValid={() => {
                      const objectArrayTemp = [...props.selectedObjectDefinition.objectArray];
                      objectArrayTemp[props.selectedObjectDefinition.index].valid = true;
                      props.selectedObjectDefinition.setObjectArray(objectArrayTemp);
                    }}
                    markAsUnValid={() => {
                      const objectArrayTemp = [...props.selectedObjectDefinition.objectArray];
                      objectArrayTemp[props.selectedObjectDefinition.index].valid = false;
                      props.selectedObjectDefinition.setObjectArray(objectArrayTemp);
                    }}
                    setComment={(comment: any) => {
                      const objectArrayTemp = [...props.selectedObjectDefinition.objectArray];
                      objectArrayTemp[
                        props.selectedObjectDefinition.index
                      ].validationComment = comment;
                      props.selectedObjectDefinition.setObjectArray(objectArrayTemp);
                    }}
                  />
                </>
              )}
            </div>
            <MainConfigForm
              item={subObjectArraySelected}
              objectType={props.selectedObjectDefinition.objectType}
              parentConfig={props.mainConfig}
              setObjectData={(objectChanged: any) => {
                const subObjectArrayTemp = [...props.selectedObjectDefinition.objectArray];
                subObjectArrayTemp[props.selectedObjectDefinition.index] = objectChanged;
                props.selectedObjectDefinition.setObjectArray(subObjectArrayTemp);
              }}
              // For page descritpion to indicate Audio or Word
              indice={props.selectedObjectDefinition.indice}
              editionMode={props.editionMode}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default function RightArea(props: any) {
  return (
    <div>
      {props.selectedObjectDefinition.index > -1 && props.selectedObjectDefinition.object && (
        <>
          <ThemeEdition {...props} />
        </>
      )}
    </div>
  );
}
