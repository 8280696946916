import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ResizablePanels from '../elementsHTML/ResizablePanels';
import BackofficeDataStore from '../../datastore/BackofficeDataStore';
import Header from './Header';

function useQuery() {
  const query = new URLSearchParams(useLocation().search);
  let queryString = '';

  for (const params of query.entries()) {
    queryString += `${params[0]}=${params[1]}&`;
  }
  if (queryString !== '') {
    queryString = queryString.slice(0, -1);
  }
  return queryString;
}

export default function EditorLayout(props: any) {
  const queryString = useQuery();
  const { id } = useParams<any>();
  const dataContextObject = props.DataContext();
  const TopNavigation = props.NavigationComponent(dataContextObject);
  const HeaderPanel = Header(TopNavigation);
  const LeftAreaPanel = props.LeftArea(dataContextObject);
  const RightAreaPanel = props.RightArea(dataContextObject);
  const MainAreaPanel = props.MainArea(dataContextObject);

  useEffect(() => {
    if (id) {
      BackofficeDataStore.getObject(
        id,
        dataContextObject.objectType,
        (resp: { [x: string]: any; id: number; locked: any }) => {
          if (resp) {
            dataContextObject.setMainConfig(resp);
            Object.keys(dataContextObject.subObjectsDefinition).forEach((key) => {
              dataContextObject.subObjectsDefinition[key].setObjectArray(resp[key]);
              if (resp.id && resp.id !== 0) {
                dataContextObject.setEditionMode(resp.enableEditMode);
              }
            });
          }
        },
      );
      const urlCompleted = `${id}?${queryString}`;
      BackofficeDataStore.getObjectTree(urlCompleted, dataContextObject.objectType, (resp: any) => {
        if (resp) {
          dataContextObject.setTreeMenu(resp);
        }
      });
    } else {
      dataContextObject.setEditionMode(true);
      BackofficeDataStore.getRootObjectTree(dataContextObject.objectType, (resp: any) => {
        if (resp) {
          dataContextObject.setTreeMenu(resp);
        }
      });
    }
  }, [id]);

  return (
    <>
      {HeaderPanel}
      <fieldset disabled={!dataContextObject.editionMode}>
        <ResizablePanels>
          {LeftAreaPanel}
          {RightAreaPanel}
          {MainAreaPanel}
        </ResizablePanels>
      </fieldset>
    </>
  );
}
