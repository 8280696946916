import React from 'react';
import { ChevronRightSharp } from '@material-ui/icons';
import SelectBoxWithTitle from '../../../elementsHTML/SelectWithTitle';
import MainSelectBox from '../Main/NavigationComponent';

const NavigationComponent = (props: any) => {

  return (
    <>
      <MainSelectBox {...props} />
      <span>
        &nbsp;
        <ChevronRightSharp style={{ fontSize: '60px', fontWeight: 'bold' }} />
        &nbsp;
      </span>
      <SelectBoxWithTitle
        list={props.treeMenu.application}
        title="application.title"
        baseUrl="/application"
        objectType="application"
      />
    </>
  );
};

export default function Navigation(props: any) {
  return <NavigationComponent {...props} />;
}
