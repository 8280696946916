import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import React, { useEffect, useState } from 'react';
import BackofficeDataStore from '../../datastore/BackofficeDataStore';
import Header from './Header';
import Modal from '@material-ui/core/Modal';
import { DataGrid } from '@material-ui/data-grid';
import Auth from '@aws-amplify/auth';

const columns = [
  { field: 'couponId', headerName: 'ID', width: 150 },
  { field: 'planId', headerName: 'Plan ID', width: 200 },
  { field: 'creationDate', headerName: 'Creation Date', width: 250 },
  { field: 'email', headerName: 'Created By', width: 250 },
  { field: 'lastUsedDate', headerName: 'Last Used Date', width: 250 },
  { field: 'description', headerName: 'Description', flex: 1 }
];

const generateCouponId = (): string => {
  return (Math.random().toString(36).substring(2, 10)).toUpperCase();
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  paper: {
    position: 'absolute',
    width: '400px',
    height: 'auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column'
  },
  closeBtn: {
    margin: '0',
    width: '70px'
  }
}));

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  };
}

const planIdToDuration: any = {
  'month_gift_plan': '1 month',
  'quarter_year_gift_plan': '3 months',
  'half_year_gift_plan': '6 months',
  'year_gift_plan': '12 months'
}

const loadData = (setCoupons: Function) => {
  BackofficeDataStore.getAllObject('coupon', (resp: any) => {
    if (resp) {
      setCoupons(resp.sort((a: any, b: any) => a.creationDate > b.creationDate ? -1 : 1));
    }
  });
}

export default function UserFollowup() {
  const [modalStyle] = React.useState(getModalStyle);
  const [coupons, setCoupons] = useState<any[]>();
  const [quantity, setQuantity] = useState<number>(1);
  const [expireInXHours, setExpireInXHours] = useState<number>(-1);
  const [open, setOpen] = React.useState(false);
  const [planType, setPlanType] = React.useState<string>('');
  const [couponId, setCouponId] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');
  const HeaderPanel = Header();
  const classes = useStyles();

  useEffect(() => {
    loadData(setCoupons);
  }, []);

  const handleOpen = () => {
    setPlanType('');
    setDescription('');
    setQuantity(1);
    setExpireInXHours(-1);
    setCouponId(generateCouponId());
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div style={{ flex: 1, overflow: 'auto', display: 'flex' }}>
        <div style={{ flex: 0.5, overflow: 'auto' }}>
          <div>Coupon ID *</div>
          <input type='text' value={couponId} maxLength={8} onChange={(e) => setCouponId(e.target.value)} />
        </div>
        <div style={{ flex: 0.5, overflow: 'auto' }}>
          <div>Plan Type *</div>
          <select
            value={planType}
            onChange={(e) => {
              setPlanType(e.target.value);
            }}
          >
            <option value="" disabled>Select a type</option>
            {Object.keys(planIdToDuration).map(planId => (
              <option key={planId} value={planId}>{planIdToDuration[planId]}</option>
            ))}
          </select>
        </div>
      </div>
      <br />
      <div style={{ flex: 1, overflow: 'auto', display: 'flex' }}>
        <div style={{ flex: 0.5, overflow: 'auto' }}>
          <div>Quantity</div>
          <input type='number' value={quantity} onChange={(e) => setQuantity(parseInt(e.target.value))} />
        </div>
        <div style={{ flex: 0.5, overflow: 'auto' }}>
          <div>Expiration in hours</div>
          <input type='number' value={expireInXHours} onChange={(e) => setExpireInXHours(parseInt(e.target.value))} />
        </div>
      </div>
      <br />
      <div style={{ flex: 1, overflow: 'auto' }}>
        <div>Description *</div>
        <textarea value={description} rows={5} onChange={(e) => setDescription(e.target.value)} style={{ width: '100%' }} />
      </div>
      <br />
      <div>
        <Button onClick={handleClose} variant="contained" className={classes.closeBtn}>Cancel</Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <Button disabled={!planType || !description || !couponId || couponId.length !== 8} onClick={async () => {
          const dataUser = await Auth.currentUserInfo();
          const newCoupon = {
            couponId: couponId,
            creationDate: new Date().toISOString(),
            quantity,
            expireInXHours,
            description,
            planId: planType,
            email: dataUser.attributes.email
          }
          BackofficeDataStore.saveUniqueObject(newCoupon, 'coupon', (data: any, err: any) => {
            if (err) alert(JSON.stringify(err.response.data.message));
            else { loadData(setCoupons); handleClose(); };
          });
        }} variant="contained" className={classes.closeBtn}>Create</Button>
      </div>
    </div>
  );

  return (
    <>
      {HeaderPanel}
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
        {!coupons ? (<>Loading</>) : (<>
          <button onClick={handleOpen} >Add Coupon</button>
          <div style={{ height: "calc(100vh - 100px)", width: '100%' }}>
            <DataGrid rows={coupons} columns={columns} pageSize={50} />
          </div>
        </>)}
      </div>
    </>
  );
}
