// @ts-nocheck
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';

const MainButtonComponent = ({
  unlockAction,
  saveAction,
  cancelAction,
  editionMode,
}: any) => {
  // const { t } = useTranslation('common');
  return (
    <>
      {!editionMode && (
        <IconButton variant="contained" onClick={unlockAction}>
          <EditIcon />
        </IconButton>
      )}
      {/* <Tooltip title={t('save')}> */}
      {editionMode && (
        <>
          <IconButton variant="contained" onClick={saveAction}>
            <SaveIcon />
          </IconButton>
          <IconButton variant="contained" onClick={cancelAction}>
            <CancelIcon />
          </IconButton>
        </>
      )}
    </>
  );
};

export default function MainControlButtons(props: any) {
  return <MainButtonComponent {...props} />;
}
