import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { ToggleButton } from '@material-ui/lab';
import ObjectOnScreenFactory from '../factories/ObjectOnScreenFactory';
import MainConfigOnScreenFactory from '../factories/MainConfigOnScreenFactory';
import TabbedListing from '../categories/common/TabbedListing';
import ImageComponent from '../elementsHTML/ImageComponent';

const screenSize = {
  height: '31.5vw',
  width: '42vw',
};

const useStyles = makeStyles({
  previewBtn: {
    '&.MuiToggleButton-root': {
      color: 'rgb(255, 255, 255, 0.4)',
      fontWeight: 'bold',
      backgroundColor: 'rgb(25, 118, 210, 0.4)',
      '&.Mui-selected': {
        color: 'white',
        backgroundColor: '#1976d2',
      },
    },
  },

  screen: {
    width: screenSize.width,
    height: screenSize.height,
    position: 'relative',
    overflow: 'auto',
    flex: 1.4,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    color: 'white',
    fontSize: '1.3vw',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    width: '100%',
    marginTop: '1vw',
    marginBottom: '1vw',
    display: 'flex',
  },
  subObjectArrayTablet: {
    width: '50vw',
    height: '34.980806vw',
    padding: '0',
    backgroundImage: 'url(/ipad_empty.png);',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subObjectArrayTabletScreen: {
    height: screenSize.height,
    width: screenSize.width,
    backgroundColor: '#303638',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    '& > div': {
      height: '100%',
    },
    '& > ::-webkit-scrollbar' : {
      width: "5px",
    },
    '& > ::-webkit-scrollbar-thumb' : {
      background: "#ededed",
      borderRadius: "6px",
    },
    position: 'relative',
  },
  thematicImage: {
    position: 'absolute',
    height: screenSize.height,
    width: screenSize.width,
  },
});

const MainAreaComponent = (props: any) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const ObjectOnScreens: any[] = [];
  
  Object.keys(props.subObjectsDefinition).forEach((key, index) => {
    const objectDefinition = props.subObjectsDefinition[key];
    ObjectOnScreens.push(
      ObjectOnScreenFactory(objectDefinition.objectType)(
        {
          editionMode: props.editionMode,
          objectArray: objectDefinition.objectArray,
          objectType: objectDefinition.objectType,
          setObjectArray: objectDefinition.setObjectArray,
          selectedObjectDefinition: props.selectedObjectDefinition,
          setSelectedObjectDefinition: props.setSelectedObjectDefinition,
          screenSize,
          parentConfig: props.mainConfig,
          paragraphs: [
            props.mainConfig.paragraph1,
            props.mainConfig.paragraph2,
            props.mainConfig.paragraph3,
          ],
        },
        key,
      ),
    );
  }, {});
  const [state, setState] = React.useState({
    displayPreview: true,
  });

  const handleChange = () => {
    setState({ ...state, displayPreview: !state.displayPreview });
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        <div style={{ width: '30%' }}>&nbsp;</div>
        <div
          style={{ width: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {props.mainConfig.name}
          {props.mainConfig.lastupdate && (
            <>
              &nbsp;(
              {t('updated')}
              :&nbsp;
              {props.mainConfig.lastupdate})
            </>
          )}
        </div>
        <div
          style={{
            width: '30%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <ToggleButton
            className={classes.previewBtn}
            value="check"
            name="displayPreview"
            selected={state.displayPreview}
            onChange={handleChange}
          >
            {t('Preview')}
          </ToggleButton>
        </div>
      </div>
      <div
        className={`panel ${classes.subObjectArrayTablet}`}
        style={{ display: !state.displayPreview ? 'none' : 'flex' }}
      >
        <div className={classes.subObjectArrayTabletScreen}>
          {(props.mainConfig.fullImage || props.mainConfig.background) && (
            <ImageComponent
              alt="theme background"
              imageKey={props.mainConfig.hasOwnProperty('background') ? props.mainConfig.background : props.mainConfig.fullImage}
              className={classes.thematicImage}
            />
          )}

          <div id="screen" className={classes.screen}>
            {MainConfigOnScreenFactory(props.objectType)()}
            {ObjectOnScreens.map((ObjectOnScreen) => ObjectOnScreen)}
          </div>
        </div>
      </div>

      <div style={{ display: state.displayPreview ? 'none' : 'flex', width: '100%' }}>
        <TabbedListing {...props} />
      </div>
    </div>
  );
};

export default function MainArea(props: any) {
  
  return (
    <div>
      <MainAreaComponent {...props} />
    </div>
  );
}
