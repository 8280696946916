import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ClearRounded from '@material-ui/icons/ClearRounded';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import { Button, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    '&.valid': {
      backgroundColor: '#5a9f6d',
      color: 'white',
    },
    '&.unvalid': {
      backgroundColor: '#db4037',
      color: 'white',
    },
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
}

export default function ValidationComponent(props: any) {
  const classes = useStyles();
  const { t } = useTranslation('common');

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <>
        <TextField
          multiline
          disabled={!props.editionMode}
          variant="outlined"
          label={t('Comment')}
          style={{ width: '100%', marginBottom: '15px', marginTop: '15px' }}
          rowsMax={10}
          value={props.object.validationComment}
          onChange={(e) => {
            if (props.setComment) props.setComment(e.target.value);
          }}
        />
      </>
      <Button onClick={handleClose} variant="contained">
        {t('Close')}
      </Button>
    </div>
  );

  return (
    <>
      <IconButton
        disabled={!props.editionMode}
        size="small"
        className={classes.button + (`${props.object.valid}` === 'true' ? ' valid' : '')}
        onClick={() => {
          if (props.markAsValid) props.markAsValid();
        }}
      >
        <CheckIcon />
      </IconButton>
      <IconButton
        size="small"
        className={classes.button + (`${props.object.valid}` === 'false' ? ' unvalid' : '')}
        onClick={() => {
          handleOpen();
          if (props.editionMode && props.markAsValid) props.markAsUnValid();
        }}
      >
        <ClearRounded />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </>
  );
}
