import { IconButton } from '@material-ui/core';
import { PlayArrow, Pause } from '@material-ui/icons';
import React, { useState } from 'react';
import FileDataStore from '../../datastore/FileDataStore';

const AudioComponent = (props: { children: any; audioKey: any; className?: string, onerror?: Function, oncanplaythrough?: Function }) => {
  const { children, onerror, oncanplaythrough } = props;
  const [audio, setAudio] = useState(new Audio());
  const [playing, setPlaying] = useState(false);

  React.useEffect(() => {
    if (props.audioKey) {
      FileDataStore.getSecuredUrlFile(props.audioKey, (fileURLTemp: any) => {
        const audioTmp = new Audio(fileURLTemp);
        audioTmp.onerror = (err) => {
          if(onerror) onerror(err);
        }
        audioTmp.oncanplaythrough = () => {
          if(oncanplaythrough) oncanplaythrough();
        }
        setAudio(audioTmp);
      });
    } else {
      setAudio(new Audio());
    }
  }, [props.audioKey, onerror]);

  const startPause = () => {
    if (playing) {
      audio.pause();
      setPlaying(false);
    } else {
      audio.onended = () => setPlaying(false);
      audio.play();
      setPlaying(true);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} className={props.className}>
        <div>{children}</div>
        {props.audioKey && (
          <div>
            <IconButton onClick={startPause}>
              {!playing && <PlayArrow />}
              {playing && <Pause />}
            </IconButton>
          </div>
        )}
      </div>
    </>
  );
};

export default function AudioInput(props: any) {
  return <AudioComponent {...props} />;
}
