import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Modal from '@material-ui/core/Modal';
import { Button } from '@material-ui/core';
import MainConfigFormFactory from '../factories/MainConfigFormFactory';
import SubObjectsPanelFactory from '../factories/SubObjectsPanelFactory';
import BackofficeDataStore from '../../datastore/BackofficeDataStore';
import MainControlButtons from '../elementsHTML/MainControlButtons';
import Collapsable from '../elementsHTML/Collapsable';

const useStyles = makeStyles((theme) => ({
  buttonPanel: {
    width: '100%',
    marginBottom: '0.6vw',
  },
  selectBox: {
    zIndex: 2,
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

const FormComponent = (props: any) => {

  const { t } = useTranslation('common');
  const [modalProperties, setModalProperties] = React.useState({
    open: false,
    text: '',
    success: false,
    errorCode: '',
    errorText: '',
  });
  const classes = useStyles();
  const MainConfigForm = MainConfigFormFactory(props.objectType);
  const SubObjectsPanels: any[] = [];
  Object.keys(props.subObjectsDefinition).forEach((key) => {
    const objectDefinitionTemp = props.subObjectsDefinition[key];
    SubObjectsPanels.push(
      //@ts-nocheck
      SubObjectsPanelFactory(objectDefinitionTemp.objectType)(
        {
          editionMode: props.editionMode,
          mainConfig: props.mainConfig,
          objectArray: objectDefinitionTemp.objectArray,
          objectType: objectDefinitionTemp.objectType,
          setObjectArray: objectDefinitionTemp.setObjectArray,
          setObjectToDelete: objectDefinitionTemp.setObjectToDelete,
          selectedObjectDefinition: props.selectedObjectDefinition,
          setSelectedObjectDefinition: props.setSelectedObjectDefinition,
        },
        key,
      ),
    );
  }, {});
  const unlockAction = () => {
    BackofficeDataStore.unlockObject(props.mainConfig.id, props.objectType, (resp: any, err: any) => {
      if (resp) {
        window.location.reload();
      } else if (err) {
        alert(err.response.data.error);
      }
    });
  };
  const saveAction = () => {
    const objectSettings = props.mainConfig;
    Object.keys(props.subObjectsDefinition).forEach((key) => {
      objectSettings[key] = props.subObjectsDefinition[key].objectArray;
      if (props.subObjectsDefinition[key].objectToDelete) {
        objectSettings[key + '_toDelete'] = props.subObjectsDefinition[key].objectToDelete;
      }
    });
    BackofficeDataStore.saveObject(
      objectSettings,
      props.objectType,
      (
        resp: { [x: string]: any; locked: any },
        err: { response: { data: { error: string; errorCode: string } } },
      ) => {
        let errorText = '';
        let errorCode = '';
        if (resp) {
          props.setMainConfig(resp);
          Object.keys(props.subObjectsDefinition).forEach((key) => {
            props.subObjectsDefinition[key].setObjectArray(resp[key]);
          });
          props.setEditionMode(!resp.locked);
          window.location.reload();
        } else {
          if (err) {
            errorText = err.response ? err.response.data.error : 'Unknown error';
            errorCode = err.response ? err.response.data.errorCode : 'Unknown error';
          }
          setModalProperties({
            open: true,
            text: 'Failed!',
            errorText,
            errorCode,
            success: false,
          });
        }
      },
    );
  };
  const cancelAction = () => {
    BackofficeDataStore.lockObject(
      props.mainConfig.id,
      props.objectType,
      (resp: any, err: { response: { data: { error: string; errorCode: string } } }) => {
        if (resp) {
          window.location.reload();

        } else if (err) {
          if (
            err.response &&
            err.response.data &&
            err.response.data.errorCode === 'UnlockedByAnother'
          ) {
            alert(`${err.response.data.error}\nThe screen will be reloaded.`);
            window.location.reload();
            return;
          }
          alert(err.response.data.error);
          console.log(err.response.data.error);
        }
      },
    );
  };
  const handleClose = () => {
    if (modalProperties.success || modalProperties.errorCode === 'UnlockedByAnother') {
      window.location.reload();
    }
    setModalProperties({
      open: false,
      text: 'Failed!',
      success: false,
      errorText: '',
      errorCode: '',
    });
  };

  return (
    <div className="leftContent">
      <Modal open={modalProperties.open} onClose={handleClose}>
        <div className={classes.paper}>
          <p id="simple-modal-description">
            {modalProperties.text}
            <p>{modalProperties.errorText}</p>
            {modalProperties.errorText === 'UnlockedByAnother' && (
              <p>The screen will be reloaded.</p>
            )}
          </p>
          <Button onClick={handleClose} variant="contained">
            {t('Close')}
          </Button>
        </div>
      </Modal>
      <div className={classes.buttonPanel}>
        <MainControlButtons
          editionMode={props.editionMode}
          cancelAction={cancelAction}
          unlockAction={() => unlockAction()}
          saveAction={() => saveAction()}
        />
      </div>
      <hr className="solid-light" style={{ marginBottom: '1rem' }} />
      <Collapsable className={classes.inputWrapper} title={props.mainConfig.name ? props.mainConfig.name : t(props.labels.objectTitle)}>
        <div>
          <fieldset disabled={!props.isRoot}>
            <MainConfigForm
              item={props.mainConfig}
              objectType={props.objectType}
              parentConfig={props.mainConfig}
              setObjectData={(newObject: any) => {
                props.setMainConfig({ ...newObject });
              
              }}
            />
          </fieldset>
        </div>
      </Collapsable>
      <hr className="solid-light" style={{ marginTop: '1rem' }} />
      {SubObjectsPanels.map((SubObjectsPanel, index) => <React.Fragment key={index}>{SubObjectsPanel}</React.Fragment>)}
    </div>
  );
};

export default function LeftArea(props: any) {
  return <FormComponent {...props} />;
}
