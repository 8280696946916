import { useState } from 'react';
import CommonDataContext from '../../common/DataContext';

export default function DataContext() {
  const [appNewsArray, setAppNewsArray] = useState([]);
  const [appNewsToDeleteArray, setAppNewsToDeleteArray] = useState([]);
  const dataContext = {
    ...CommonDataContext(),
    objectType: 'application',
    labels: {
      objectTitle: 'application.title',
    },
    isRoot: true,
    subObjectsDefinition: {
      appNews: {
        objectType: 'appNews',
        objectArray: appNewsArray,
        setObjectArray: setAppNewsArray,
        objectToDelete: appNewsToDeleteArray,
        setObjectToDelete: setAppNewsToDeleteArray,
      },
    },
  };

  return dataContext;
}
