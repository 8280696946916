

export default function ObjectDefinitionFactory(objectType: string) {
  const common = {
    objectType,
    newItem: { name: '' },
    baseUrl: false,
    isSequential: false,
    objectPreview: (object: any) => (<>{object.name}</>),
    nameAutoGenerated: false
  };
  switch (objectType) {
    case 'application':
      return {
        ...common,
        baseUrl: '/application',
        subObjectType: ['appNews'],
      }
    case 'feed':
      return {
        ...common,
        baseUrl: '/feed',
        subObjectType: ['feedItem'],
      }
    default:
      return {
        ...common
      };
  }
}
