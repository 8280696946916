//@ts-nocheck
import React, { useState } from 'react';
import './Collapsable.scss';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as IconArrow } from './icon_arrow.svg';

const useStyles = makeStyles({
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },

  collapseTitle: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    '& svg': {
      width: 10,
      marginLeft: 5,
    }
  },
});

const CollapsableComponent = ({ title, children, className }: any) => {
  const [collapsed, seeCollapsed] = useState(false);
  const classes = useStyles();

  const getWrapperStyle = () => {
    return collapsed ? { height: 'auto', display: 'flex' } : { height: 0, display: 'none' };
  }

  const toggleCollapse = () => {
    seeCollapsed(!collapsed);
  }

  return (
    <div className={className}>
      <div onClick={toggleCollapse} className={classes.collapseTitle + (collapsed ? " arrowDown" : " arrowLeft")}>{title}<IconArrow /></div>
      <div className={classes.flexCenter} style={getWrapperStyle()}>
        {children}
      </div>
    </div>
  );
};

export default function Collapsable(props: any) {
  return (
    <CollapsableComponent {...props} />
  );
}
