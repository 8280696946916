import React, { Component } from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import { withAuthenticator } from 'aws-amplify-react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import EditorLayout from './component/layout/EditorLayout';
import * as MainComponents from './component/categories/platform/Main';
import * as ApplicationComponents from './component/categories/platform/Application';
import * as FeedComponents from './component/categories/platform/Feed';
import CouponsComponent from './component/layout/Coupons';
import AvatarsComponent from './component/layout/Avatars';

import '@aws-amplify/ui/dist/style.css';

import awsconfig from './aws-exports';

const withAuthenticatorConfig: any = {
  usernameAttributes: 'email',
  signUpConfig: { hiddenDefaults: ['phone_number'], },
};

Amplify.configure(awsconfig);

class App extends Component {
  render() {
    return (
      <div>
        <React.Suspense fallback={<div>Loading...</div>}>
          <Router>
            <Switch>
              <Route path="/" exact render={Main} />
              <Route path="/main/:id" exact render={Main} />
              <Route path="/application/:id" exact render={Application} />
              <Route path="/coupons" exact render={Coupons} />
              <Route path="/avatars" exact render={Avatars} />
              <Route path="/feed" exact render={Feed} />
              <Route path="/feed/:id" exact render={Feed} />
            </Switch>
          </Router>
        </React.Suspense>
      </div>
    );
  }
}

export default withAuthenticator(App, withAuthenticatorConfig);

const Main = (props: any) => (
  <>
    <EditorLayout {...MainComponents} {...props} />
  </>
);

const Application = (props: any) => (
  <>
    <EditorLayout {...ApplicationComponents} {...props} />
  </>
);

const Coupons = (props: any) => (
  <>
    <CouponsComponent {...ApplicationComponents} {...props} />
  </>
);

const Feed = (props: any) => (
  <>
    <EditorLayout {...FeedComponents} {...props} />
  </>
);

const Avatars = (props: any) => (
  <>
    <AvatarsComponent {...ApplicationComponents} {...props} />
  </>
);
