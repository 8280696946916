import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Add, CloseRounded, CloudUpload } from '@material-ui/icons';
import ImageComponent from './ImageComponent';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileDataStore from '../../datastore/FileDataStore';
import S3Selector from './S3Selector';

const ImageUploaderComponent = ({ title, readOnly, callback, image, height, imageRatio, width, className, style, fileType }: any) => {
  const imageHeight = height ? height : 82.5;
  const imageWidth = imageHeight / (imageRatio ? imageRatio : 0.75);

  const useStyles = makeStyles({
    uploaderWrapper: {
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      alignItems: 'center',
    },
    imagePreviewPanel: {
      width: width ? width : imageWidth + 'px',
      height: imageHeight + 'px',
      backgroundColor: 'white',
      display: 'flex',
      borderRadius: '0.3vw',
      justifyContent: 'center',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'white',
        opacity: '0.2',
      },
      '& img': {
        borderRadius: '0.3vw',
      },
    },
    uploadButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: imageWidth + 'px',
      height: imageHeight + 'px',
    },
    spinner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: imageWidth + 'px',
      height: imageHeight + 'px',
    },
    title: {
      paddingTop: '15px',
    },
  });

  const [loaded, setLoaded] = useState('');
  const hiddenSmallImageInput = React.useRef<HTMLInputElement>(null);
  const classes = useStyles();

  return (
    <div
      className={classes.uploaderWrapper + ' ' + className}
      style={style}>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={hiddenSmallImageInput}
        onChange={(event: any) => {
          setLoaded('loading');
          let target = event.currentTarget;
          if (target) {
            FileDataStore.uploadFile(target?.files[0], (data: any) => {
              callback(data);
              setLoaded('done');
            });
          }
        }} />
      {!readOnly && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <S3Selector callback={callback} fileType={fileType === 'all' ? '' : 'images'} size='small' />
          <IconButton onClick={() => hiddenSmallImageInput.current?.click()} size={'small'}>
            <CloudUpload />
          </IconButton>
          <IconButton onClick={() => callback(undefined)} size={'small'}>
            <CloseRounded />
          </IconButton>
        </div>
      )}
      <div className={classes.imagePreviewPanel + ' imagePreviewPanel'}>
        {loaded === 'loading' && (
          <div className={classes.spinner}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {loaded !== 'loading' && (<>
          <ImageComponent width="100%" imageKey={image} alt={title}>
            {!readOnly && (
              <div className={classes.uploadButton}>
                <IconButton>
                  <Add />
                </IconButton>
              </div>
            )}
          </ImageComponent>
        </>)}
      </div>
      { title !== '' && (
        <div className={classes.title}>{title}</div>
      )}
    </div>
  );
};

export default function ImageUploader(props: {
  title: any;
  height?: any;
  callback: any;
  image: any;
  imageRatio: any;
  readOnly?: boolean;
  width?: any;
  className?: string;
  style?: any;
  fileType?: string | 'images';
}) {
  return (
    <ImageUploaderComponent
      title={props.title}
      readOnly={props.readOnly}
      className={props.className}
      callback={props.callback}
      image={props.image}
      imageRatio={props.imageRatio}
      width={props.width}
      height={props.height}
      style={props.style}
      fileType={props.fileType}
    />
  );
}
