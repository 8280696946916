import IconButton from '@material-ui/core/IconButton';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ObjectDefinitionFactory from '../../factories/ObjectDefinitionFactory';
import InputWithDeleteButton from '../../elementsHTML/InputWithDeleteButton';
import { ReactSortable } from "react-sortablejs";

const useStyles = makeStyles({
  buttonPanel: {
    width: '100%',
    marginBottom: '0.6vw',
  },
  subtitle: {
    fontSize: '16px',
  },
  selectBox: {
    zIndex: 2,
  },
  objectItemWrapper: {
    opacity: 1,
  },
});

const FormComponent = (props: any) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const objectDefinition = ObjectDefinitionFactory(props.objectType);
  return (
    <>
      <div className={classes.subtitle}>
        <span>
          <span style={{ paddingRight: '.3rem' }}>  {props.objectArray ? props.objectArray.length : ''}</span>
          {props.mainConfig && props.mainConfig.name === 'Adverts' ? 'Adverts' : t(`${props.objectType}.title`)}
        </span>
        <IconButton
          disabled={!props.editionMode}
          onClick={() => {
            props.setObjectArray((prevArray: any) => {
              let newItem = objectDefinition.newItem;
              if (newItem && newItem.name) {
                newItem.name = newItem.name.replace('{index}', prevArray && prevArray.length ? prevArray.length + 1 : 1);
              }
              if (prevArray && prevArray.length) {
                return [...prevArray, objectDefinition.newItem];
              }
              return [objectDefinition.newItem];
            });
          }}
        >
          <AddBoxIcon />
        </IconButton>
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ReactSortable
          list={props.objectArray ? props.objectArray.filter((l: any) => l !== null).map((item: string, index: number) => ({ index, item })) : []}
          setList={(value) => {
            const newArray = value.map((item, index: number) => {
              const result = item.item; 
              result['orderIndex'] = index + 1;
              return result;
            });
            props.setObjectArray(newArray);
          }}>

          {props.objectArray &&
            props.objectArray.length &&
            props.objectArray.map((objectItem: any, index: number) => (
              <div className={classes.objectItemWrapper} key={`${props.objectType}_${index}`}>
                <InputWithDeleteButton
                  rowIndex={Number(index) + 1}
                  editable={props.editionMode ? (!objectDefinition.isSequential || index === props.objectArray.length - 1) : false}
                  deleteAction={() => {
                    if (objectItem.id !== undefined) {
                      props.setObjectToDelete((prev: any) => [...prev, objectItem]);
                    }
                    props.setObjectArray([...props.objectArray.filter((item: any) => item !== objectItem)]);
                    props.setSelectedObjectDefinition({});
                  }}
                  fieldName="name"
                  selectedObject={props.selectedObjectDefinition}
                  item={objectItem}
                  onClick={() => {
                    props.setSelectedObjectDefinition({
                      object: objectItem,
                      objectType: props.objectType,
                      index,
                      objectArray: props.objectArray,
                      setObjectArray: props.setObjectArray,
                    });
                  }}
                  objectDefinition={objectDefinition}
                  onChange={(value: any) => {
                    const objectArrayTemp = [...props.objectArray];
                    objectArrayTemp[index].name = value;
                    props.setObjectArray(objectArrayTemp);
                  }}
                  onDataSelected={(value: { position: any; idTemp: string }) => {
                    const objectArrayTemp = [...props.objectArray];
                    const { position } = objectArrayTemp[index];
                    objectArrayTemp[index] = value;
                    value.position = position;
                    props.setObjectArray(objectArrayTemp);
                    props.setSelectedObjectDefinition({
                      ...props.selectedObjectDefinition,
                      object: objectArrayTemp[index],
                    });
                  }}
                />
              </div>
            )
            )}
        </ReactSortable>
      </div>
    </>
  );
};

export default function SubObjectsPanel(props: any, key: number | string) {
  return <FormComponent {...props} key={key} />;
}
