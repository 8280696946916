import React from 'react';
import TextFieldAndImages from '../../elementsHTML/TextFieldAndImages';
import ObjectDefinitionFactory from '../../factories/ObjectDefinitionFactory';

const MainConfigFormComponent = (props: any) => {
  const objectDefinition = ObjectDefinitionFactory(props.objectType);
  return (
    <TextFieldAndImages
      item={props.item}
      objectDefinition={objectDefinition}
      setObjectData={(newObject: any) => {
        props.setObjectData(newObject);
      }}
    />
  );
};

export default function MainConfigForm(props: any) {
  return (
    <div>
      <MainConfigFormComponent {...props} />
    </div>
  );
}
