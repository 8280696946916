import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import BackofficeDataStore from '../../datastore/BackofficeDataStore';

const InputWithDeleteButtonComponent = ({
  deleteAction,
  rowIndex,
  selectedObject,
  item,
  fieldName,
  onClick,
  onChange,
  onDataSelected,
  objectDefinition,
  editable,
  indice,
}: any) => {
  const useStyles = makeStyles({
    themeWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    selected: {
      border: indice !== 'audio' ? '2px solid #5A9F6D' : '2px solid red',
    },
    themeBox: {
      fontSize: '1em',
      width: '85%',
      padding: '2px',
      backgroundColor: 'white',
    },
    themeIndex: {
      fontSize: '1em',
      width: '15%',
      padding: '9px',
      color: 'white',
      backgroundColor: '#808080',
      fontWeight: 'bold',
    },
    indexSelected: {
      backgroundColor: indice !== 'audio' ? '#5A9F6D' : 'red',
    },
  });

  const classes = useStyles();
  const [objectList, setObjectList] = useState([]);
  return (
    <div className={classes.themeWrapper} key={`${objectDefinition.objectType}_${rowIndex}`}>
      <IconButton tabIndex="1" onClick={deleteAction} disabled={!editable}>
        <DeleteIcon />
      </IconButton>
      <fieldset disabled={objectDefinition.nameAutoGenerated}>
      <div onClick={onClick}>
        <Autocomplete
          id={`${objectDefinition.objectType}_${rowIndex}`}
          options={objectList || []}
          inputValue={item && item[fieldName] ? item[fieldName] : ''}
          getOptionSelected={(option: any) => option.id}
          getOptionLabel={(option) => option.title}
          style={{ width: 160 }}
          freeSolo
          disableClearable
          className={`${classes.themeBox} ${
            selectedObject && item === selectedObject.object ? classes.selected : ''
          }`}
          onFocus={onClick}
          onInputChange={(e, value, reason) => {
            if (reason !== 'reset') {
              if (value.length > 3 && objectDefinition.queryUrl) {
                BackofficeDataStore.query(
                  value,
                  objectDefinition.queryUrl,
                  (resp: React.SetStateAction<never[]>) => {
                    setObjectList(resp);
                  },
                );
              }
              onChange(value);
            }
          }}
          onChange={(event, value) => {
            if (value) {
              BackofficeDataStore.getObject(value.id, objectDefinition.getObjectUrl, (resp: any) => {
                onDataSelected(resp);
              });
            }
          }}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </div>
      </fieldset>
      <div
        className={`${classes.themeIndex} ${
          selectedObject && item === selectedObject.object
            ? `${classes.selected} ${classes.indexSelected}`
            : ''
        }`}
      >
      
        {rowIndex}
      </div>
    </div>
  );
};

export default function InputWithDeleteButton(props: any) {
  return <InputWithDeleteButtonComponent {...props} />;
}
