import { Storage } from 'aws-amplify';

export default class FileDataStore {

  static getUrlFile = (key: string, callback: Function, size?: string | number) => {
    callback(process.env.REACT_APP_IMAGE_API + key + (size ? "?tr=w-" + size : ''));
  };

  static listAllFile = (fileType: string | undefined, callback: Function) => {
    Storage.list(fileType ? fileType : '', {
      customPrefix: {
        public: ''
      }
    }).then((results: any) => {
      callback(results);
    })
  }

  static getSecuredUrlFile = (key: string, callback: Function) => {
    Storage.get(key, {
      customPrefix: {
        public: ''
      }
    }).then((url: any) => {
      callback(url)
    });
  };

  static getUrlImage(key: string, callback: Function, size?: string | number) {
    if (process.env.REACT_APP_IMAGE_API) {
      return FileDataStore.getUrlFile(key, callback, size);
    } else {
      return FileDataStore.getSecuredUrlFile(key, callback);
    }
  }

  static uploadFile = (file: any, callback: Function) => {
    if (!file) return;
    let folder: string = 'images';
    if (file.name.toLowerCase().indexOf('.mp3') > -1) folder = 'audios';
    if (file.name.toLowerCase().indexOf('.mp4') > -1) folder = 'videos';
    const options: any = {
      customPrefix: {
        public: ''
      }
    };
    if (!file.name) return;
    if (file.name.toLowerCase().indexOf('.svg') > -1) {
      options.contentType = 'image/svg+xml';
    }
    Storage.put(`${folder}/${file.name}`, file, options)
      .then((result: any) => {
        callback(result.key);
      })
      .catch((err) => console.log(err));
  };

}

