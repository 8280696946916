import { API, Auth } from 'aws-amplify';

async function getOptions(initialOptions: any) {
  const finalOptions = { ...initialOptions };
  if (!finalOptions.headers) {
    finalOptions.headers = {};
  }
  const userProperties = await getCurrentUser();

  if (userProperties) {
    finalOptions.headers.Authorization = `Bearer ${userProperties.signInUserSession.idToken.jwtToken}`;
  }
  return finalOptions;
}

async function getCurrentUser() {
  return Auth.currentAuthenticatedUser();
}

export default class JWTApi {
  static get = async (apiName: string, path: string, options?: any) => {
    return API.get(apiName, path, await getOptions(options));
  };

  static put = async (apiName: string, path: string, options?: any) => {
    return API.put(apiName, path, await getOptions(options));
  };

  static post = async (apiName: string, path: string, options?: any) => {
    return API.post(apiName, path, await getOptions(options));
  };
}
