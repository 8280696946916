// @ts-nocheck
import React, { PropsWithChildren } from 'react';

export default class ResizablePanels extends React.Component {

  props: PropsWithChildren;

  eventHandler = null;

  state = {
    isDragging: false,
    panels: [200, 200, 300],
  };

  componentDidMount() {
    this._myself.addEventListener('mousemove', this.resizePanel);
    this._myself.addEventListener('mouseup', this.stopResize);
    this._myself.addEventListener('mouseleave', this.stopResize);
  }

  startResize = (event: { clientX: any }, index: any) => {
    this.setState({
      isDragging: true,
      currentPanel: index,
      initialPos: event.clientX,
    });
  };

  stopResize = () => {
    if (this.state.isDragging) {
      this.setState(({ panels, currentPanel, delta }: any) => ({
        isDragging: false,
        panels: {
          ...panels,
          [currentPanel]: (panels[currentPanel] || 0) + delta,
          [panels.length - 1]: (panels[panels.length - 1] || 0) - delta,
        },
        delta: 0,
        currentPanel: null,
      }));
    }
  };

  resizePanel = (event: { clientX: number }) => {
    if (this.state.isDragging) {
      const delta = event.clientX - this.state.initialPos;
      this.setState({
        delta,
      });
    }
  };

  render() {
    return (
      <div
        className="panel-container"
        onMouseUp={() => this.stopResize()}
        ref={(c) => (this._myself = c)}
      >
        <div className="panel leftBar" style={{ width: this.state.panels[0] }}>
          {this.props?.children[0]}
        </div>
        <div
          onMouseDown={(e) => this.startResize(e, 0)}
          key={`resizer_${0}`}
          style={this.state.currentPanel === 0 ? { left: this.state.delta } : {}}
          className="resizer"
        />

        <div key={`panel_${1}`} className="panel rightBar" style={{ width: this.state.panels[1] }}>
          {this.props?.children[1]}
        </div>
        <div
          onMouseDown={(e) => this.startResize(e, 1)}
          key={`resizer_${1}`}
          style={this.state.currentPanel === 1 ? { left: this.state.delta } : {}}
          className="resizer"
        />

        <div
          key={`panel_${0}`}
          className="panel mainArea"
          style={{ width: `calc(100% - ${this.state.panels[0]}px - ${this.state.panels[1]}px)` }}
        >
          {this.props?.children[2]}
        </div>
      </div>
    );
  }
}
