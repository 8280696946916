import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import ImageUploader from '../../../elementsHTML/ImageUploader';
import AudioUploader from 'src/component/elementsHTML/AudioUploader';
import { useState } from 'react';
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

const useStyles = makeStyles({
  textfield: {
    marginTop: '5%',
    marginBottom: '5%',
  },
  imageUploader: {
    flexDirection: 'row',
    justifyContent: 'flex-start !important',
    marginTop: '5%',
    marginBottom: '5%',
    '& > div': {
      marginRight: '5%'
    }
  },
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  formcontrol: {
    display: 'flex',
    marginBottom: '5%',
    '& label': {
      width: '50%',
      fontSize: '0.9em'
    },
    '&.multiline': {
      display: 'flex',
      flexDirection: 'column',
      '& label': {
        width: '100%'
      }
    }
  },
  imageComponent: {
    height: '40px',
    width: 'auto',
    marginRight: '2%'
  }
});

const MainConfigComponent = (props: any) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  return (
    <div className={classes.formWrapper}>
      <TextField
        id="name"
        className={classes.textfield}
        label={t('Name')}
        variant="outlined"
        value={props.item.name}
        onChange={(e) => {
          props.item.name = e.target.value;
          props.setObjectData(props.item);
        }}
      />
      <div className={classes.formcontrol + ' multiline'}>
        <label id="progressionImage-label">Rubric Title</label>
        <TextField
          id="rubricTitle"
          type="text"
          className={classes.textfield}
          label=''
          variant="outlined"
          value={props.item.rubricTitle ? props.item.rubricTitle : ''}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            props.item.rubricTitle = e.target.value;
            props.setObjectData(props.item);
          }}
        />
      </div>
      <div className={classes.formcontrol + ' multiline'}>
        <label id="progressionImage-label">Short Description</label>
        <TextField
          id="shortDesc"
          type="text"
          className={classes.textfield}
          label=''
          variant="outlined"
          value={props.item.shortDesc ? props.item.shortDesc : ''}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            props.item.shortDesc = e.target.value;
            props.setObjectData(props.item);
          }}
        />
      </div>
      <div className={classes.formcontrol + ' multiline'}>
        <label id="progressionImage-label">Publication Date</label>
        <TextField
          id="publicationDate"
          type="date"
          className={classes.textfield}
          label=''
          variant="outlined"
          value={props.item.publicationDate ? props.item.publicationDate : ''}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            props.item.publicationDate = e.target.value;
            props.setObjectData(props.item);
          }}
        />
      </div>

      <div className={classes.formcontrol + ' multiline'}>
        <label id="progressionImage-label">Thumbnails Image</label>
        <ImageUploader
          title=''
          className={classes.imageUploader}
          style={{ flexDirection: 'row' }}
          image={props.item.imageHomepage ? props.item.imageHomepage : ''}
          imageRatio={1}
          callback={(fileKey: any) => {
            if (fileKey) {
              props.item.imageHomepage = fileKey;
              props.setObjectData(props.item);
            }
          }}
        />
      </div>

      <div className={classes.formcontrol + ' multiline'}>
        <label id="progressionImage-label">Video ID</label>
        <TextField
          id="videoID"
          type="text"
          className={classes.textfield}
          label=''
          variant="outlined"
          value={props.item.video ? props.item.video : ''}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            props.item.video = e.target.value;
            props.setObjectData(props.item);
          }}
        />
      </div>
      <div className={classes.formcontrol + ' multiline'}>
        <label id="progressionImage-label">Mobile Video ID</label>
        <TextField
          id="videoID"
          type="text"
          className={classes.textfield}
          label=''
          variant="outlined"
          value={props.item.videoMobile ? props.item.videoMobile : ''}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => {
            props.item.videoMobile = e.target.value;
            props.setObjectData(props.item);
          }}
        />
      </div>
      <div className={classes.formcontrol + ' multiline'}>
        <label id="progressionImage-label">Full Image</label>
        <ImageUploader
          title=''
          className={classes.imageUploader}
          style={{ flexDirection: 'row' }}
          image={props.item.image ? props.item.image : ''}
          imageRatio={1.25}
          callback={(fileKey: any) => {
            if (fileKey) {
              props.item.image = fileKey;
              props.setObjectData(props.item);
            }
          }}
        />
      </div>
      <div className={classes.formcontrol + ' multiline'}>
        <label id="progressionImage-label">Mobile Full Image</label>
        <ImageUploader
          title=''
          className={classes.imageUploader}
          style={{ flexDirection: 'row' }}
          image={props.item.imageMobile ? props.item.imageMobile : ''}
          imageRatio={1}
          callback={(fileKey: any) => {
            if (fileKey) {
              props.item.imageMobile = fileKey;
              props.setObjectData(props.item);
            }
          }}
        />
      </div>

      <div className={classes.formcontrol}>
        <label id="progressionImage-label">Audio</label>
        <AudioUploader
          title=''
          className={classes.imageUploader}
          style={{ flexDirection: 'row' }}
          audio={props.item.audio ? props.item.audio : ''}
          callback={(fileKey: any) => {
            if (fileKey) {
              props.item.audio = fileKey;
              props.setObjectData(props.item);
            }
          }}
        />
      </div>
      <div style={{ width: '100%' }}>
        <ReactQuill
          key={'richText_' + props.item.id}
          theme='snow'
          value={props.item.description ? props.item.description : ''}
          onChange={(richText) => {
            props.item.description = richText;
            props.setObjectData(props.item);
          }}
          style={{ minHeight: '300px' }}
        />
      </div>
    </div>
  );
};

export default function LibraryMainConfig(props: any) {
  return (
    <>
      <MainConfigComponent {...props} />
    </>
  );
}
