import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { ListAltOutlined } from '@material-ui/icons';
import FileDataStore from '../../datastore/FileDataStore';
import ReactTable from './ReactTable';

const cloudWidth = 6.7;
const cloudHeight = 6.7 / 1.333333333;

const useStyles = makeStyles((theme) => ({
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: cloudWidth + 'vw',
    height: cloudHeight + 'vw',
  },
  paper: {
    position: 'absolute',
    width: 'auto',
    height: '90vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: 'flex',
    flexDirection: 'column'
  },
  closeBtn: {
    margin: '0',
    width: '50px'
  }
}));

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
}

const S3SelectorComponent = (props: { callback: Function, fileType?: string, size: string | 'medium' }) => {
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [fileList, setFileList] = React.useState<any[]>([]);
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
    setLoading(true);
    FileDataStore.listAllFile(props.fileType, (results: any) => {
      setLoading(false);
      setFileList(results.filter((f: any) => f.key.split('/').length === 2 && !f.key.endsWith('/')));
    })
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div style={{ flex: 1, overflow: 'auto' }}>
        {loading ? (
          <div className={classes.spinner}>
            <CircularProgress color="secondary" />
          </div>
        ) : (<>
          <ReactTable data={fileList} callback={(selected: string) => { props.callback(selected); handleClose(); }} />
        </>)}
      </div>
      <Button onClick={handleClose} variant="contained" className={classes.closeBtn}>Close</Button>
    </div>
  );

  return (<>
    <IconButton onClick={handleOpen} size={props.size ? 'small' : 'medium'}>
      <ListAltOutlined />
    </IconButton>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  </>
  );
};

export default function S3Selector(props: any) {
  return (
    <S3SelectorComponent {...props} />
  );
}
