import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { CloseRounded, CloudUpload } from '@material-ui/icons';
import AudioComponent from './AudioComponent';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileDataStore from '../../datastore/FileDataStore';
import S3Selector from './S3Selector';

const audioWidth = 6.7;
const audioHeight = 6.7 / 1.333333333;

const useStyles = makeStyles({
  audioPreviewPanel: {
    width: audioWidth + 'vw',
    height: audioHeight + 'vw',
    backgroundColor: 'white',
    display: 'flex',
    borderRadius: '0.3vw',
    justifyContent: 'center',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'white',
      opacity: '0.2',
    },
    '& img': {
      borderRadius: '0.3vw',
    },
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: audioWidth + 'vw',
    height: audioHeight + 'vw',
  },
  spinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: audioWidth + 'vw',
    height: audioHeight + 'vw',
  },
  title: {
    paddingTop: '0.5vw',
  },
});

const AudioUploaderComponent = ({ title, callback, audio, fileType, readOnly }: any) => {
  const [loaded, setLoaded] = useState('');
  const hiddenSmallAudioInput = React.useRef<HTMLInputElement>(null);
  const classes = useStyles();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
      }}
    >
      <input
        type="file"
        style={{ display: 'none' }}
        ref={hiddenSmallAudioInput}
        onChange={(event) => {
          if (event.target.files && event.target.files.length) {
            setLoaded('loading');
            FileDataStore.uploadFile(event.target.files[0], (data: any) => {
              setLoaded('done');
              callback(data);
            });
          }
        }}
      />
      <div style={{ display: 'flex' }}>
        {loaded === 'loading' && (
          <div className={classes.spinner}>
            <CircularProgress color="secondary" />
          </div>
        )}
        {loaded !== 'loading' && (<>
          <AudioComponent width="100%" audioKey={audio} alt="Audio loader">
            {!readOnly && (<>
              <S3Selector callback={callback} fileType={fileType === 'all' ? '' : 'audios'} size={'small'} />
              <IconButton size={'small'}
                onClick={() => {
                  if (hiddenSmallAudioInput.current) hiddenSmallAudioInput.current.click();
                }}
              >
                <CloudUpload />
              </IconButton>
              <IconButton onClick={() => callback(undefined)} size={'small'}>
                <CloseRounded />
              </IconButton>
            </>)}
          </AudioComponent>
        </>)}
      </div>
      {title && (
        <div className={classes.title}>{title}</div>
      )}
    </div>
  );
};

export default function AudioUploader(props: any) {
  return (
    <AudioUploaderComponent title={props.title} callback={props.callback} audio={props.audio} fileType={props.fileType} readOnly={props.readOnly} />
  );
}
