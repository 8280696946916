import React, { useEffect, useState } from 'react';
import './ValidationView.scss'
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import ObjectDefinitionFactory from '../../factories/ObjectDefinitionFactory';
import BackofficeStore from '../../../datastore/BackofficeDataStore';
import Collapsable from '../../elementsHTML/Collapsable';

const useStyles = makeStyles({
  flexDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  panelWrapper: {
    padding: '15px',
    margin: '15px',
    borderRadius: '10px',
    backgroundColor: '#F2F2F2',
    '&.selected': {
      '& .objectIndex': {
        backgroundColor: '#5A9F6D',
        color: 'white',
        fontWeight: 'bold'
      }
    }
  },
  title: {
    fontSize: '1.1em',
  },
  objects: {
    flexWrap: 'wrap',
  },
  validationTitle: {
    marginRight: '15px',
    width: '23.5%',
    marginTop: '15px',
  },
  objectTitle: {
    backgroundColor: 'black',
    width: '100%',
    color: 'white',
    padding: '4px 0',
    borderRadius: '10px',
    textAlign: 'center',
    display: 'block',
  },
});

const goBtn = (objectDef: any, object: any) => {
  if (!objectDef.baseUrl) return (<></>);
  return (
    <IconButton size="small" onClick={() => { document.location.href = `${objectDef.baseUrl}/${object.id}`; }}>
      <SearchIcon style={{ margin: 0, width: 'auto', transform: 'none' }} />
    </IconButton>
  )
}

const title = (objectDef: any, object: any, key: string) => (
  <div>
    <div style={{ width: '50px', height: '50px' }}>{objectDef.objectPreview(object)}</div>
    <div>
      <span>{object.name} {goBtn(objectDef, object)}</span>
      <br />
      {'(' + (object[key] ? object[key].length : 0) + ' ' + key + ')'}
    </div>
  </div>
)

const objectView = (object: any, objectType: any) => {
  if (!object) return;
  const objectDef: any = ObjectDefinitionFactory(objectType);
  if (objectDef.subObjectType) {
    return (objectDef.subObjectType.map((key: string, index: number) => (
      <div key={index} className='collapsable'>
        <Collapsable title={(title(objectDef, object, key))} >
          {object[key] && object[key].map((subObject: any, subIndex: number) => (
            <div key={subIndex}>{objectView(subObject, key)}</div>
          ))}
        </Collapsable >
      </div>
    )))
  } else {
    return (<>{objectDef.objectPreview(object)}</>)
  }
}

export default function ValidationView(props: any) {
  const classes = useStyles();
  const objectDef: any = ObjectDefinitionFactory(props.objectType);
  const [objectDesc, setObjectDesc] = useState<any>({});
  const [loading, setLoading] = useState<any>(true);

  useEffect(() => {
    if (props.mainConfig.id !== undefined) {
      BackofficeStore.getObjectTreeDesc(props.mainConfig.id, props.parentObjectType, (resp: any, err: any) => {
        setObjectDesc(resp);
        setLoading(false);
      });
    }
  }, [props.mainConfig.id, props.parentObjectType]);
  return (
    <>
      {props.objectArray &&
        props.objectArray.map(
          (
            object: any,
            index: string | number,
          ) => {
            const isSelected = props.selectedObjectDefinition && props.selectedObjectDefinition.object === object;
            const currentObject: any = objectDesc && objectDesc[props.objectType] && objectDesc[props.objectType][index]
              ? objectDesc[props.objectType][index] : undefined;
            return (
              <div key={props.objectType + index} className={classes.panelWrapper + (isSelected ? ' selected' : '')} onClick={() => {
                props.setSelectedObjectDefinition({
                  object: object,
                  objectType: props.objectType,
                  index,
                  objectArray: props.objectArray,
                  setObjectArray: props.setObjectArray,
                })
              }}>
                <div className={`${classes.title} ${classes.flexDiv}`}>
                  <div style={{ width: '100px', marginRight: '1%' }}>{objectDef.objectPreview(object)}</div>
                  <div>
                    <span className='objectIndex'>{`${Number(index) + 1}.`}</span>
                    {object.name}&nbsp;
                  </div>
                  {goBtn(objectDef, object)}
                </div>
                {loading ? (<>Loading sub objects</>) : (
                  <div className='subObjects'>
                    {objectDef.subObjectType && currentObject
                      && objectDef.subObjectType.map((key: string) => (
                        <React.Fragment key={key}>
                          {currentObject[key] && currentObject[key].map((subObject: any) => (objectView(subObject, key)))}
                        </React.Fragment>
                      ))}
                  </div>
                )}
              </div>
            );
          },
        )}
    </>
  );
}
