import React from 'react';
import SelectBoxWithTitle from '../../../elementsHTML/SelectWithTitle';

const NavigationComponent = (props: any) => {
  
  return (
    <>
      <SelectBoxWithTitle
        list={props.treeMenu.feed}
        title="feed.title"
        baseUrl="/feed"
        objectType="feed"
      />
    </>
  );
};

export default function Navigation(props: any) {
  return <NavigationComponent {...props} />;
}
