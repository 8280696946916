import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  header: {
    paddingTop: '0.7vw',
  },
  title: {
    width: '10%',
    fontSize: '1.2vw',
  },
  navigation: {
    width: '70%',
    display: 'flex',
    alignItems: 'center',
  },
  transButton: {
    width: '30%',
    textAlign: 'right',
    marginRight: '1.2vw',
  },
  frButton: {
    marginRight: '1.2vw',
  },
  enButton: {
    marginRight: '1.2vw',
  },
  button: {
    marginRight: '1.2vw',
    paddingRight: '1.2vw',
    paddingLeft: '1.2vw',
  }
});

export default function Header(TopNavigation?: React.ReactNode) {
  const { i18n } = useTranslation('common');
  const classes = useStyles();

  return (
    <div className="header">
      <div className={classes.title}>BDOUIN ADMIN</div>
      <div className={classes.navigation}>{TopNavigation}</div>
      <div className={classes.transButton}>

        &nbsp;&nbsp;&nbsp;&nbsp;
        <button className={classes.frButton} onClick={() => document.location.href = '/'}>
          Home
        </button>
        &nbsp;
        <button className={classes.frButton} onClick={() => document.location.href = '/feed'}>
          Feed
        </button>
        &nbsp;
        <button className={classes.frButton} onClick={() => document.location.href = '/coupons'}>
          Coupons
        </button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <button className={classes.frButton} onClick={() => i18n.changeLanguage('fr')}>
          fr
        </button>
        <button className={classes.enButton} onClick={() => i18n.changeLanguage('en')}>
          en
        </button>
      </div>
    </div>
  );
}
