// @ts-nocheck
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocation } from 'react-router-dom';

function useQuery() {
  const query = new URLSearchParams(useLocation().search);
  let queryString = '';

  for (const params of query.entries()) {
    queryString += `${params[0]}=${params[1]}&`;
  }
  if (queryString !== '') {
    queryString = queryString.slice(0, -1);
  }
  return queryString;
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: '80%',
  },
  selectBox: {
    width: '100%',
  },
  selectWrapper: {
    opacity: '1',
  },
}));

const SelectBoxWithTitleComponent = (props: {
  list: any[];
  title: string | undefined;
  baseUrl: string;
  objectType: string;
}) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const queryString = useQuery();

  return (
    <div className={classes.selectWrapper}>
      {props.list && (
        <Autocomplete
          id={props.title}
          options={props.list}
          value={props.list.filter((m: { selected: any }) =>  m.selected)[0]}
          getOptionSelected={(option) => option.id}
          getOptionLabel={(option) => option.name}
          style={{ width: 180 }}
          onClose={(event, reason) => {
            const indexSelected = event.target
              ? event.target.getAttribute('data-option-index')
              : -1;
            if (reason === 'select-option' && indexSelected !== -1) {
              const item = props.list[indexSelected];
              document.location = `${props.baseUrl}/${item.id}${
                queryString !== '' && props.objectType === 'scene' ? `?${queryString}` : ''
              }`;
            }
          }}
          onChange={(value, item) => {
            if (item) {
              document.location = `${props.baseUrl}/${item.id}${
                queryString !== '' && props.objectType === 'scene' ? `?${queryString}` : ''
              }`;
            }
          }}
          renderInput={(params) => (
            <TextField {...params} label={t(props.title)} variant="outlined" fullWidth />
          )}
        />
      )}
    </div>
  );
};

export default function SelectBoxWithTitle(props: any) {
  return <SelectBoxWithTitleComponent {...props} />;
}
