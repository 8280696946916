import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useTranslation } from 'react-i18next';
import ValidationViewFactory from '../../factories/ValidationViewFactory';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  tabs: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    '& header': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent',
    },
    '& button': {
      backgroundColor: '#C3C3C3',
      borderRadius: '30px',
      opacity: '1',
      fontWeight: 'bold',
      paddingTop: '2px',
      paddingBottom: '2px',
      marginLeft: '4px',
      marginRight: '4px',
    },
    '& button.Mui-selected': {
      backgroundColor: '#5A9F6D',
    },
    '& button.MuiTab-root': {
      minHeight: '0',
    },
  },
}));

export default function TabbedListing(props: {
  subObjectsDefinition: { [x: string]: any };
  mainConfig: any;
  editionMode: any;
  objectType: string;
  setSelectedObjectDefinition: any;
  selectedObjectDefinition: any;
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation('common');

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };

  return (
    <div className={classes.tabs}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} aria-label="Components" centered>
          {props.subObjectsDefinition &&
            Object.keys(props.subObjectsDefinition).map((key, index) => (
              <Tab key={key} label={t(`${props.subObjectsDefinition[key].objectType}.title`)} />
            ))}
        </Tabs>
      </AppBar>

      {props.subObjectsDefinition &&
        Object.keys(props.subObjectsDefinition).map((key, index) => {
          const ValidationView = ValidationViewFactory(props.subObjectsDefinition[key].objectType);
          return (
            props.subObjectsDefinition[key].objectArray &&
            props.subObjectsDefinition[key].objectArray.length > 0 && (
              <TabPanel key={index} value={value} index={index}>
                <ValidationView
                  {...props.subObjectsDefinition[key]}
                  key={index}
                  mainConfig={props.mainConfig}
                  parentObjectType={props.objectType}
                  editionMode={props.editionMode}
                  setSelectedObjectDefinition={props.setSelectedObjectDefinition}
                  selectedObjectDefinition={props.selectedObjectDefinition}
                />
              </TabPanel>
            )
          );
        })}
    </div>
  );
}
