import { useState } from 'react';
import CommonDataContext from '../../common/DataContext';

export default function DataContext() {
  const [applicationArray, setApplicationArray] = useState([]);
  const [applicationToDeleteArray, setApplicationToDeleteArray] = useState([]);
  const dataContext = {
    ...CommonDataContext(),
    objectType: 'main',
    labels: {
      objectTitle: 'main.title',
    },
    isRoot: true,
    subObjectsDefinition: {
      application: {
        objectType: 'application',
        objectArray: applicationArray,
        setObjectArray: setApplicationArray,
        objectToDelete: applicationToDeleteArray,
        setObjectToDelete: setApplicationToDeleteArray,
      },
    },
  };

  return dataContext;
}
