import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import ImageUploader from './ImageUploader';

const useStyles = makeStyles({
  inputWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > div': {
      padding: '.5rem 0',
    }
  },
  imageUploader: {
    marginRight: '10px',
  }
});

const TextFieldAndImagesComponent = (props: {
  objectDefinition: any,
  firstImageName: any;
  secondImageName: any;
  firstImageTitle: any;
  secondImageTitle: any;
  item: { [x: string]: any; name: any };
  setObjectData: (arg0: any) => void;
  imageRatio: any;
}) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  const firstImageName = props.firstImageName ? props.firstImageName : 'smallImage';
  const secondImageName = props.secondImageName ? props.secondImageName : 'fullImage';
  const firstImageTitle = props.firstImageTitle ? props.firstImageTitle : 'image.small';
  const secondImageTitle = props.secondImageTitle ? props.secondImageTitle : 'image.full';

  return (
    <div>
      <div className={classes.inputWrapper}>
        <div style={{ textAlign: 'center' }}>
          {props.objectDefinition.nameAutoGenerated ? (
            <h2>{props.item.name}</h2>
          ) : (
              <TextField
                id="thematicSheetName"
                label={t('name')}
                variant="outlined"
                value={props.item.name}
                onChange={(e) => {
                  props.item.name = e.target.value;
                  props.setObjectData(props.item);
                }}
              />
            )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div className={classes.imageUploader}>
            <ImageUploader
              title={t(firstImageTitle)}
              image={props.item[firstImageName]}
              imageRatio={props.imageRatio}
              callback={(fileKey: any) => {
                if (fileKey) {
                  props.item[firstImageName] = fileKey;
                  props.setObjectData(props.item);
                }
              }}
            />
          </div>
          <div>
            <ImageUploader
              title={t(secondImageTitle)}
              image={props.item[secondImageName]}
              imageRatio={props.imageRatio}
              callback={(fileKey: any) => {
                if (fileKey) {
                  props.item[secondImageName] = fileKey;
                  props.setObjectData(props.item);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default function TextFieldAndImages(props: any) {
  return (
    <div>
      <TextFieldAndImagesComponent {...props} />
    </div>
  );
}
