/* eslint-disable no-unused-vars */
import DefaultMainConfigForm from '../categories/common/MainConfigForm';
import MainConfig from '../categories/platform/Main/MainConfigForm';
import ApplicationMainConfig from '../categories/platform/Application/MainConfigForm';
import AppNewsMainConfig from '../categories/platform/AppNews/MainConfigForm';
import FeedMainConfig from '../categories/platform/Feed/MainConfigForm';
import FeedItemMainConfig from '../categories/platform/FeedItem/MainConfigForm';

export default function MainConfigFormFactory(objectType: string) {
  switch (objectType) {
    case 'main':
      return MainConfig;
    case 'application':
      return ApplicationMainConfig;
    case 'appNews':
      return AppNewsMainConfig;
      case 'feed':
        return FeedMainConfig;
      case 'feedItem':
        return FeedItemMainConfig;
    default:
      return DefaultMainConfigForm;
  }
}
