import { useState } from 'react';
import CommonDataContext from '../../common/DataContext';

export default function DataContext() {
  const [feedItemArray, setFeedItemArray] = useState([]);
  const [feedItemToDeleteArray, setFeedItemToDeleteArray] = useState([]);
  return {
    ...CommonDataContext(),
    objectType: 'feed',
    labels: {
      objectTitle: 'feed.title',
    },
    isRoot: true,
    subObjectsDefinition: {
      feedItem: {
        objectType: 'feedItem',
        objectArray: feedItemArray,
        setObjectArray: setFeedItemArray,
        objectToDelete: feedItemToDeleteArray,
        setObjectToDelete: setFeedItemToDeleteArray,
      },
    },
  };
}
