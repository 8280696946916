import { useState } from 'react';

export default function DataContext() {
  const [mainConfig, setMainConfig] = useState({ name: '' });
  const [subObjectArray, setSubObjectArray] = useState([]);
  const [selectedSubObjectIndex, setSelectedSubObjectIndex] = useState(-1);
  const [selectedObjectDefinition, setSelectedObjectDefinition] = useState({
    object: {},
    objectType: '',
    index: -1,
    objectArray: [],
  });
  const [treeMenu, setTreeMenu] = useState({});
  const [editionMode, setEditionMode] = useState(false);

  return {
    mainConfig,
    setMainConfig,
    subObjectArray,
    setSubObjectArray,
    selectedSubObjectIndex,
    setSelectedSubObjectIndex,
    selectedObjectDefinition,
    setSelectedObjectDefinition,
    treeMenu,
    setTreeMenu,
    editionMode,
    setEditionMode,
  };
}
