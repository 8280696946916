import JWTApi from './JWTApi';

const apiName = 'ApiGatewayRestApi';
const path = 'backoffice';

export default class BackofficeStore {
  static getObject = (id: string, objectType: string, callback: Function) => {
    JWTApi.get(apiName, `${path}/getObject/${objectType}/${id}`)
      .then((resp) => {
        callback(resp);
      })
      .catch((err) => callback(null, err));
  };

  static getAllObject = (objectType: string, callback: Function) => {
    JWTApi.get(apiName, `${path}/getAllObject/${objectType}`)
      .then((resp) => {
        callback(resp);
      })
      .catch((err) => callback(null, err));
  };

  static query = (string: string, queryURL: string, callback: Function) => {
    JWTApi.get(apiName, `${queryURL}/${string}`)
      .then((resp) => {
        callback(resp);
      })
      .catch((err) => callback(null, err));
  };

  static getObjectTree = (id: string, objectType: string, callback: Function) => {
    JWTApi.get(apiName, `${path}/tree/${objectType}/${id}`)
      .then((resp) => {
        callback(resp);
      })
      .catch((err) => callback(null, err));
  };


  static getObjectTreeDesc = (id: string, objectType: string, callback: Function) => {
    JWTApi.get(apiName, `${path}/treedesc/${objectType}/${id}`)
      .then((resp) => {
        callback(resp);
      })
      .catch((err) => callback(null, err));
  };

  static getRootObjectTree = (objectType: string, callback: Function) => {
    JWTApi.get(apiName, `${path}/tree/${objectType}`)
      .then((resp) => {
        callback(resp);
      })
      .catch((err) => callback(null, err));
  };

  static saveObject = (data: any, objectType: string, callback: Function) => {
    JWTApi.post(apiName, `${path}/saveObject/${objectType}`, { body: data })
      .then((resp) => {
        callback(resp);
      })
      .catch((err) => {
        console.log(err);
        callback(null, err);
      });
  };

  static saveUniqueObject = (data: any, objectType: string, callback: Function) => {
    JWTApi.post(apiName, `${path}/saveUniqueObject/${objectType}`, { body: data })
      .then((resp) => {
        callback(resp);
      })
      .catch((err) => {
        console.log(err);
        callback(null, err);
      });
  };
  static unlockObject = (objectId: string, objectType: string, callback: Function) => {
    JWTApi.put(apiName, `${path}/unlock/${objectType}/${objectId}`)
      .then((resp) => {
        callback(resp);
      })
      .catch((err) => {
        console.log(err);
        callback(null, err);
      });
  };
  static lockObject = (objectId: string, objectType: string, callback: Function) => {
    JWTApi.put(apiName, `${path}/lock/${objectType}/${objectId}`)
      .then((resp) => {
        callback(resp);
      })
      .catch((err) => {
        console.log(err);
        callback(null, err);
      });
  };
  static refreshUnlock = (objectId: string, objectType: string, callback: Function) => {
    JWTApi.put(apiName, `${path}/refresh/unlock/${objectType}/${objectId}`)
      .then((resp) => {
        callback(resp);
      })
      .catch((err) => {
        console.log(err);
        callback(null, err);
      });
  };
}
