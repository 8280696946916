import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import ImageUploader from 'src/component/elementsHTML/ImageUploader';

const useStyles = makeStyles({
  textfield: {
    marginTop: '5%',
    marginBottom: '5%',
  },
  imageUploader: {
    flexDirection: 'row',
    justifyContent: 'flex-start !important',
    marginTop: '5%',
    marginBottom: '5%',
    '& > div': {
      marginRight: '5%'
    }
  },
  formcontrol: {
    display: 'flex',
    marginBottom: '5%',
    '& label': {
      width: '50%',
      fontSize: '0.9em'
    },
    '&.multiline': {
      display: 'flex',
      flexDirection: 'column',
      '& label': {
        width: '100%'
      }
    }
  },
  imageComponent: {
    height: '40px',
    width: 'auto',
    marginRight: '2%'
  }
});

const MainConfigComponent = (props: any) => {
  const { t } = useTranslation('common');
  const classes = useStyles();
  return (<>
    <div>
      <TextField
        id="name"
        className={classes.textfield}
        label={t('Name')}
        variant="outlined"
        value={props.item.name}
        onChange={(e) => {
          props.item.name = e.target.value;
          props.setObjectData(props.item);
        }}
      />
      <div className={classes.formcontrol + ' multiline'}>
        <label id="progressionImage-label">Support us Image</label>
        <ImageUploader
          title=''
          className={classes.imageUploader}
          style={{ flexDirection: 'row' }}
          image={props.item.supportUs ? props.item.supportUs : ''}
          imageRatio={1.25}
          callback={(fileKey: any) => {
            props.item.supportUs = fileKey;
            props.setObjectData(props.item);
          }}
        />
      </div>
    </div>
  </>);
};

export default function MainConfig(props: any) {
  return (
    <div>
      <MainConfigComponent {...props} />
    </div>
  );
}
