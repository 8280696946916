import DefaultValidationView from '../categories/common/ValidationView';



export default function ValidationViewFactory(objectType: any) {
  switch (objectType) {
    default:
      return DefaultValidationView;
  }
}
