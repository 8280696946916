import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageComponent from '../../elementsHTML/ImageComponent';
import ObjectDefinitionFactory from '../../factories/ObjectDefinitionFactory';

const useStyles = makeStyles({
  objectArrayTabletContent: {
    position: "absolute",
    top: "10%",
    left: "12%",
    justifyItems : "center",
    maxHeight: '100%',
    display: 'flex',
    flexWrap: "wrap",
    
  },
  objectArrayBox: {
    margin: '1vw',
    backgroundColor: '#ededed',
    flex: '0 19%',
    zIndex: 1,
    width: "200px",
    textAlign: "center"
  },
  selected: {
    border: '0.2vw solid #5A9F6D',
  },
  image: {
    borderRadius: '10px',
    margin: '10px',
  },
});

const ObjectOnScreenComponent = (props: {
  objectArray: any[];
  selectedObjectDefinition: { object: any };
  setSelectedObjectDefinition: (arg0: {
    object: any;
    objectType: any;
    index: any;
    objectArray: any;
    setObjectArray: any;
  }) => void;
  objectType: any;
  setObjectArray: any;
}) => {
  const classes = useStyles();
  return (
    <div className={classes.objectArrayTabletContent}>
      {props.objectArray &&
        props.objectArray.length &&
        props.objectArray.map(function (
          subObject: { smallImage?: any; fullImage?: any; background?: any; name: React.ReactNode; level: React.ReactNode },
          index: string | number,
        ) {
          const objectDef = ObjectDefinitionFactory(props.objectType);
          return (
            <div
              key={`objectArrayBox${index}`}
              className={
                classes.objectArrayBox +
                (props.selectedObjectDefinition &&
                  props.selectedObjectDefinition.object === subObject
                  ? ` ${classes.selected}`
                  : '')
              }
              onClick={() => {
                props.setSelectedObjectDefinition({
                  object: subObject,
                  objectType: props.objectType,
                  index,
                  objectArray: props.objectArray,
                  setObjectArray: props.setObjectArray,
                });
              }}
            >
              {objectDef.objectPreview ? (
                <div >
                  {objectDef.objectPreview(subObject)}
                </div>
              ) : (
                  <ImageComponent
                    imageKey={subObject.hasOwnProperty('smallImage') ? subObject.smallImage :
                      (subObject.hasOwnProperty('background') ? subObject.background : (subObject.fullImage && subObject.fullImage['FR'] ? subObject.fullImage['FR'] : (typeof subObject.fullImage === 'string' ? subObject.fullImage : '')))}
                    alt="theme background"
                    className={classes.image}
                  >
                    <div style={{ backgroundColor: 'red' }} className={classes.image} />
                  </ImageComponent>
                )}
              {Number(index) + 1}
              .&nbsp;
              {subObject.name}
              <br />
              {subObject.level}
            </div>
          );
        })}
    </div>
  );
};

export default function ObjectOnScreen(props: any, key: string | number | null | undefined) {
  return (
    <div key={key}>
      <ObjectOnScreenComponent {...props} />
    </div>
  );
}
