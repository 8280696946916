import React, { useEffect, useState } from 'react';
import FileDataStore from '../../datastore/FileDataStore';

const ImageComponent = (props: {
  children: any;
  imageKey: any;
  onClick?: any;
  alt: string | undefined;
  width: string | number | undefined;
  className: string | undefined;
}) => {
  const [fileURL, setFileURL] = useState('');
  const { children } = props;

  useEffect(() => {
    if (props.imageKey) {
      FileDataStore.getUrlImage(props.imageKey, (fileURLTemp: any) => {
        setFileURL(fileURLTemp);
      });
    } else {
      setFileURL('');
    }
  }, [props.imageKey]);

  return (
    <>
      {fileURL && (
        <img
          src={fileURL}
          alt={props.alt}
          width={props.width}
          onClick={props.onClick}
          className={props.className}
        />
      )}
      {!fileURL && <>{children}</>}
    </>
  );
};

export default function Image(props: any) {
  return <ImageComponent {...props} />;
}
